@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-ExtraBold.woff2") format("woff2"),
      url("./fonts/Gilroy-ExtraBold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-Light.woff2") format("woff2"),
      url("./fonts/Gilroy-Light.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no-spin-button::-webkit-outer-spin-button,
  .no-spin-button::-webkit-inner-spin-button {
    appearance: none;
  }
}

/* Paymentsense */
iframe {
  width: 100%;
}

iframe.threeDs {
  width: 370px;
  height: 366px;
  margin: 20px 0 0 -185px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}

/* React Toast */
.Toastify .Toastify__toast-container {
  width: 100%;
  padding: 0;
}

.Toastify .Toastify__toast {
  margin: 8px;
  font-family: inherit;
  font-weight: bold;
  color: #fff;
  background-color: #8e24aa;
}

.Toastify .Toastify__toast-container--top-center {
  top: 0;
  left: 0;
  transform: translateX(0);
}
